import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faTachometerAlt as faTachometerAltFad,
  faCogs as faCogsFad,
  faUsers as faUsersFad,
  faUsersClass as faUsersClassFad,
  faFileContract as faFileContractFad,
  faBooks as faBooksFad,
  faIndustryAlt as faIndustryAltFad,
  faBoxes as faBoxesFad,
  faBell as faBellFad,
  faExchangeAlt as faExchangeAltFad,
  faFileExclamation as faFileExclamationFad,
  faFileCheck as faFileCheckFad,
  faFileCircleInfo as faFileCircleInfoFad,
  faListCheck as faListCheckFad,
  faWindow as faWindowFad,
  faBars as faBarsFad,
  faGasPump as faGasPumpFad,
  faBuilding as faBuildingFad,
  faAddressBook as faAddressBookFad,
  faFile as faFileFad,
  faScrewdriverWrench as faScrewdriverWrenchFad,
  faList as faListFad,
  faLanguage as faLanguageFad,
  faUsersCog as faUsersCogFad,
  faUserCog as faUserCogFad,
  faAsterisk as faAsteriskFad,
  faListDropdown as faListDropdownFad,
  faPenField as faPenFieldFad,
} from '@fortawesome/pro-duotone-svg-icons'

import {
  faTachometerAlt,
  faClipboardCheck,
  faTasks,
  faBooks,
  faExchangeAlt,
  faCogs,
  faBars,
  faArrowAltFromTop,
  faCubes,
  faIndustryAlt,
  faNotEqual,
  faWarehouseAlt,
  faTruckContainer,
  faTruckLoading,
  faCheckCircle,
  faPalletAlt,
  faFileContract,
  faCircleNotch,
  faBoxes,
  faUsers,
  faFileExclamation,
  faFileCheck,
  faPen,
  faGripHorizontal,
  faGripLines,
  faSync,
  faTimes,
  faCheckDouble,
  faPlusSquare,
  faDraftingCompass,
  faFileCsv,
  faFilter,
  faFileExcel,
  faCloudDownload,
  faAngleRight,
  faAngleLeft,
  faTrashAlt,
  faArrowLeft,
  faBell,
  faChartLineDown,
  faArrowUp,
  faPalette,
  faCheck,
  faSlash,
  faQuestion,
  faArrowDown,
  faTrashRestore,
  faEye,
  faBox,
  faSearch,
  faCartPlus,
  faArrowCircleDown,
  faMinusSquare,
  faMinus,
  faInboxIn,
  faHeart as fasHeart,
  faBadge,
  faArrowsRotate,
  faCartXmark,
  faReply,
  faCalendarDay,
  faCalendarDays,
  faLocationDot,
  faGauge,
  faGaugeLow,
  faGaugeMin,
  faHand,
  faGear,
  faPlusLarge,
  faChevronRight,
  faArrowTurnDownRight,
  faMagnifyingGlass,
  faUsersCog,
  faUserCog,
  faGasPump,
  faBuilding,
  faAddressBook,
  faScrewdriverWrench,
  faLanguage,
  faList,
  faBoxArchive,
  faFileExport,
  faFileImport,
  faSignOut,
  faChevronUp,
  faChevronDown,
  faCircleQuestion,
  faFileXmark,
  faFileWaveform,
  faSpinnerThird,
  faAsterisk,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faHeart as farHeart,
  faShieldExclamation as farShieldExclamation,
  faShieldCheck as farShieldCheck,
  faXmarkToSlot as farXmarkToSlot,
} from '@fortawesome/pro-regular-svg-icons'

import {
  faWindow as faWindowLight,
  faBars as faBarsLight,
} from '@fortawesome/pro-light-svg-icons'
// Import and put icons here
library.add(
  faBars,
  faBarsLight,
  faBarsFad,
  faTachometerAlt,
  faClipboardCheck,
  faTasks,
  faBooks,
  faExchangeAlt,
  faCogs,
  faArrowAltFromTop,
  faCubes,
  faIndustryAlt,
  faNotEqual,
  faWarehouseAlt,
  faTruckContainer,
  faTruckLoading,
  faCheckCircle,
  faPalletAlt,
  faFileContract,
  faCircleNotch,
  faBoxes,
  faUsers,
  faFileExclamation,
  faFileCheck,
  faPen,
  faGripHorizontal,
  faGripLines,
  faSync,
  faTimes,
  faCheckDouble,
  faPlusSquare,
  faDraftingCompass,
  fasHeart,
  farHeart,
  faFileCsv,
  faFilter,
  faFileExcel,
  faCloudDownload,
  faAngleLeft,
  faAngleRight,
  faTrashAlt,
  faArrowLeft,
  faChartLineDown,
  faBell,
  faArrowDown,
  faArrowUp,
  faQuestion,
  faEye,
  faTrashRestore,
  faPalette,
  faCheck,
  faSlash,
  faBadge,
  faBars,
  faBox,
  faSearch,
  faCartPlus,
  faArrowCircleDown,
  faMinusSquare,
  faMinus,
  faInboxIn,
  faArrowsRotate,
  faCartXmark,
  faReply,
  faCalendarDay,
  faCalendarDays,
  faLocationDot,
  faGauge,
  faGaugeLow,
  faGaugeMin,
  faHand,
  faGear,
  faPlusLarge,
  faChevronRight,
  faTachometerAltFad,
  faCogsFad,
  faUsersFad,
  faUsersClassFad,
  faFileContractFad,
  faBooksFad,
  faIndustryAltFad,
  faBoxesFad,
  faBellFad,
  faExchangeAltFad,
  faFileExclamationFad,
  faFileCheckFad,
  faFileCircleInfoFad,
  faListCheckFad,
  faWindowFad,
  faWindowLight,
  faArrowTurnDownRight,
  faMagnifyingGlass,
  faGasPumpFad,
  faBuildingFad,
  faAddressBookFad,
  faFileFad,
  faScrewdriverWrenchFad,
  faListFad,
  faLanguageFad,
  faUsersCogFad,
  faUserCogFad,
  faUsersCog,
  faUserCog,
  faGasPump,
  faBuilding,
  faAddressBook,
  faScrewdriverWrench,
  faLanguage,
  faList,
  faBoxArchive,
  faFileExport,
  faFileImport,
  faSignOut,
  faListDropdownFad,
  faPenFieldFad,
  faChevronUp,
  faChevronDown,
  faCircleQuestion,
  farShieldExclamation,
  farShieldCheck,
  faFileXmark,
  farXmarkToSlot,
  faFileWaveform,
  faSpinnerThird,
  faAsteriskFad,
  faAsterisk
)
